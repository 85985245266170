<template>
  <div class="table_wrapper">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1" editable>
          Details
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2" editable>
          Translation
        </v-stepper-step>
        <v-divider></v-divider>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="p-3">
            <v-row align="center">
              <v-col cols="12">
                <v-select
                  label="Please Select Category"
                  item-text="title"
                  item-value="id"
                  v-model="form.category_id"
                  :items="categories"
                  :loading="loading"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <h5 class="red--text">Attachment</h5>

                <v-row no-gutters>
                  <v-col
                    v-for="(ph, index) in form.files"
                    :key="index"
                    cols="6"
                    sm="4"
                    md="3"
                    lg="2"
                  >
                    <v-card class="pa-2" outlined tile>
                      <v-img
                        lazy-src="https://via.placeholder.com/200x200"
                        max-height="200"
                        max-width="200"
                        class="m-auto"
                        @click="(dialogImage = true), (dialogImageUrl = ph)"
                        :src="ph"
                      ></v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="form.quantity"
                  label="Quantity"
                  :loading="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  label="Preferred Price Unit"
                  item-text="name"
                  item-value="id"
                  v-model="form.preferred_price_unit_id"
                  :items="units"
                  :loading="loading"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <h5 class="red--text">Shipping Method</h5>
              </v-col>
              <v-col cols="3">
                <v-select
                  label="Destination Country"
                  item-text="name"
                  item-value="id"
                  @change="getCities"
                  v-model="form.destination_country_id"
                  :items="countries"
                  :loading="loading"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  label="Destination City"
                  item-text="name"
                  item-value="id"
                  v-model="form.destination_city_id"
                  :items="cities"
                  :loading="loading"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-model="form.destination_address"
                  label="Destination Address"
                  :loading="loading"
                ></v-textarea>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Please Select Shipping Terms"
                  item-text="name"
                  item-value="id"
                  v-model="form.shipping_term_id"
                  :items="shippingterms"
                  :loading="loading"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Please Select Shipping Methods"
                  item-text="name"
                  item-value="id"
                  v-model="form.shipping_type"
                  :items="shippings"
                  :loading="loading"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <h5 class="red--text">Payment</h5>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  prefix="$"
                  v-model="form.preferred_price"
                  label="Preferred Unit Price"
                  :loading="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  label="Preferred Price Currency"
                  item-text="name"
                  item-value="id"
                  v-model="form.preferred_price_currency_id"
                  :items="currencies"
                  :loading="loading"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  label="Accepted Payment Types"
                  item-text="name"
                  item-value="id"
                  v-model="form.payment_type_id"
                  :items="paymenttypes"
                  :loading="loading"
                ></v-select>
              </v-col>
            </v-row>

            <v-btn color="primary" @click="e1 = 2">
              Next
            </v-btn>
            <v-btn
              class="ml-3"
              color="green"
              dark
              :disabled="dialog"
              :loading="dialog"
              v-on:click="saveAllData"
            >
              Save
            </v-btn>
          </v-card>

          <v-dialog v-model="dialogImage" width="600">
            <v-card>
              <v-img :src="dialogImageUrl"></v-img>
            </v-card>
          </v-dialog>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="p-3">
            <v-toolbar flat color="primary" dark>
              <v-toolbar-title>Translations</v-toolbar-title>
            </v-toolbar>
            <v-tabs vertical>
              <v-tab v-for="(item, index) in form.translations" :key="index">
                {{ item.lang.toUpperCase() }}
              </v-tab>

              <v-tab-item
                v-for="(item, index) in form.translations"
                :key="'tab-' + index"
              >
                <v-card flat>
                  <v-card-text>
                    <v-text-field
                      v-model="item.title"
                      :label="'Translation Title ' + item.lang.toUpperCase()"
                    ></v-text-field>
                    <v-textarea
                      :value="item.description"
                      v-model="item.description"
                      :label="
                        'Translation Description ' + item.lang.toUpperCase()
                      "
                    ></v-textarea>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>

            <v-btn text @click="e1 = 1">
              Prev
            </v-btn>
            <v-btn
              class="ml-3"
              color="green"
              dark
              :disabled="dialog"
              :loading="dialog"
              v-on:click="saveAllData"
            >
              Save
            </v-btn>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="p-3">
          Loading.. Please wait.
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "RfqEdit",
  data() {
    return {
      e1: 1,
      dialogImage: false,
      dialogImageUrl: "",
      dialog: true,
      loading: true,
      translations: [],
      categories: [],
      currencies: [],
      countries: [],
      cities: [],
      shippings: [],
      shippingterms: [],
      paymenttypes: [],
      units: [],
      form: {
        id: null,
        quantity: "",
        title: "",
        description: "",
        member_id: null,
        category_id: null,
        preferred_price: null,
        destination_address: null,
        destination_city_id: null,
        destination_country_id: null,
        preferred_price_currency_id: null,
        preferred_price_unit_id: null,
        shipping_term_id: null,
        shipping_type: null,
        payment_type_id: null,
        translations: [],
        files: [],
        unit_id: null,
      },
      token: localStorage.getItem("authToken"),
    };
  },
  methods: {
    saveAllData: function() {
      this.dialog = true;
      this.form.id = Number(this.$route.params.id);

      axios
        .put(
          process.env.VUE_APP_API_URL +
            "quotation/" +
            this.$route.params.id +
            "?api_token=" +
            this.token,
          this.form
        )
        .then((res) => {
          this.dialog = false;
          this.$router.push({
            name: "Rfq",
          });
        })
        .catch((err) => console.log(err));
    },
    setCategory: function(item) {
      this.form.category_id = item.id;
    },
    getCategories: function() {
      let url = "https://gowawe.com/api/admin/category?api_token=" + this.token;
      axios.get(url).then((res) => {
        this.categories = res.data.data;
        this.getPaymentTypes();
      });
    },
    getUnits: function() {
      let url = "https://gowawe.com/api/units";
      axios.get(url).then((res) => {
        const response = res.data.data;
        if (response.length > 0) {
          this.units = response;
        }
      });
    },
    getCurrencies: function() {
      let url = "https://dashboard.gowawe.com/getCurreny";
      axios.post(url).then((res) => {
        this.currencies = res.data;

        this.getCategories();
      });
    },
    getShipping: function() {
      const fields = [
        {
          field: "lookup_type_id",
          op: "=",
          value: 2,
        },
      ];
      let url =
        "https://gowawe.com/api/admin/lookup/filter?page=1&per_page=100&api_token=" +
        this.token;
      axios
        .post(url, {
          fields,
        })
        .then((res) => {
          res.data.data.data.forEach((item) => {
            this.shippings.push({
              id: item.id,
              name: item.name,
            });
          });

          this.getShippingTerms();
        });
    },
    getShippingTerms: function() {
      let url = "https://gowawe.com/api/en/shipping-terms";
      axios.get(url).then((res) => {
        const response = res.data.data;
        if (response && response.length > 0) {
          this.shippingterms = response;
        }
        this.getCurrencies();
      });
    },
    getCountry: function() {
      let url =
        "https://gowawe.com/api/admin/country/filter?page=1&per_page=1000&api_token=" +
        this.token;
      axios.post(url).then((res) => {
        res.data.data.data.forEach((item) => {
          this.countries.push({
            id: item.id,
            name: item.name,
          });
        });
        this.getCities();
        this.getShipping();
      });
    },
    getPaymentTypes: function() {
      let url = "https://gowawe.com/api/admin/payment-types";
      axios.get(url).then((res) => {
        res.data.data.forEach((item) => {
          this.paymenttypes.push({
            id: item.id,
            name: item.name,
          });
        });
      });
      this.loading = false;
      this.dialog = false;
    },
    getCities: function() {
      this.cities = [];
      let url =
        "https://gowawe.com/api/admin/country/" +
        this.form.destination_country_id +
        "/cities?api_token=" +
        this.token;
      axios.get(url).then((res) => {
        res.data.data.forEach((item) => {
          this.cities.push({
            id: item.id,
            name: item.name,
          });
        });
      });
    },
    getData: function(id) {
      let url = "https://gowawe.com/api/admin/quotation/" + id;
      axios
        .get(url)
        .then((res) => {
          const resp = res.data;

          this.form.id = resp.id;
          this.form.quantity = resp.quantity;
          this.form.title = resp.title;
          this.form.description = resp.description;
          this.form.member_id = resp.member_id;
          this.form.category_id = resp.category_id;
          this.form.preferred_price = resp.preferred_price;
          this.form.destination_address = resp.destination_address;
          this.form.destination_country_id = resp.destination_country_id;
          this.form.destination_city_id = resp.destination_city_id;
          this.form.preferred_price_currency_id =
            resp.preferred_price_currency_id;
          this.form.preferred_price_unit_id = resp.preferred_price_unit_id;
          this.form.shipping_term_id = resp.shipping_term_id;
          this.form.shipping_type = resp.shipping_type;
          this.form.unit_id = resp.unit_id;
          this.form.payment_type_id = resp.payment_type_id;
          this.form.files = resp.files;

          const th = this;
          const translationsArr = Object.values(res.data.translations);
          if (translationsArr.length > 0) {
            Object.keys(res.data.translations).forEach((item, index) => {
              th.form.translations.push({
                lang: item,
                title: translationsArr[index].title,
                description: translationsArr[index].description,
              });
            });
          }

          this.getUnits();
          this.getCountry();
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getData(this.$route.params.id);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
